import axios from "axios"
import store from "../../store"
import Axios from "../../config/Axios" // custom axios instance
import { userAuthActions } from "../reducers/userAuth.reducer"
import { generateRequestId } from "../../utils/helperFunctions"
import { decrypt, encrypt, generateHeader } from "../../utils/security"

const baseUrl = process.env.REACT_APP_BASE_URL
const ivFE = process.env.REACT_APP_ENCRYPTION_IV
const keyFE = process.env.REACT_APP_ENCRYPTION_KEY

export const validateAccountNumber = async (accountNumber) => {
  try {
    const response = await Axios(`/Account/ValidateAccountNumber?AccountNumber=${accountNumber}`)
    const data = await response?.data?.data
    return data
  } catch (error) {
    throw new Error(error?.message)
  }
}

export const signUpFCMBUser = async (details) => {
  let payload

  try {
    const accountDetails = await validateAccountNumber(details.accountNumber)
    if (accountDetails) {
      payload = {
        userType: 0,
        password: details.password,
        phone: accountDetails.mobileNumber,
        email: accountDetails.emailAddress,
        identifier: accountDetails.accountNumber,
        fullName: accountDetails.accountName,
        bvn: accountDetails.biometric_ID,
        requestId: generateRequestId(),
      }

      const response = await Axios.post("/OnBoarding/CreateAccount", { data: encrypt(payload) })
      const isSuccessful = await response?.data?.isSuccess
      if (isSuccessful) {
        return payload.email
      }
    }
  } catch (error) {
    throw new Error(error?.message)
  }
}

export const signUpNonFCMBUser = async (details) => {
  const payload = {
    bvn: "",
    phone: "",
    userType: 1,
    fullName: "",
    identifier: "",
    email: details.email,
    password: details.password,
    requestId: generateRequestId(),
  }

  try {
    const response = await Axios.post("/OnBoarding/CreateAccount", { data: encrypt(payload) })
    const data = await response?.data
    return data
  } catch (error) {
    throw new Error(error?.message)
  }
}

export const getLoggedInUserDetails = async (token) => {
  const configs = {
    headers: {
      Authorization: `Bearer ${token}`,
      ...generateHeader(),
    },
  }
  try {
    const response = await axios.get(baseUrl + "/api/Profile/GetUserProfile", configs)
    const decryptedData = JSON.parse(decrypt(response?.data))
    const data = await decryptedData?.data

    return data
  } catch (error) {
    throw new Error(error?.message)
  }
}

export const refreshAccessToken = async () => {
  let storage = JSON.parse(decrypt(JSON.parse(localStorage.getItem("GRO")), keyFE, ivFE))
  const { token, refreshToken } = store.getState()?.userAuth?.data
  const configs = {
    headers: {
      ...generateHeader(),
    },
  }
  const payload = { token, refreshToken, requestId: generateRequestId() }
  try {
    const response = await axios.post(baseUrl + "/api/Onboarding/RefreshToken", payload, configs)
    const data = await response?.data
    const newData = await { ...storage, data: { ...storage?.data, ...data.data } }
    localStorage.setItem("GRO", JSON.stringify(encrypt(newData, keyFE, ivFE)))
    store.dispatch(userAuthActions.update(newData))

    return data
  } catch (error) {
    throw new Error(error?.message)
  }
}

export const loginUser = async (details) => {
  let payload = details
  payload.requestId = generateRequestId()
  payload.channel = 1
  payload.remember = false

  try {
    const response = await Axios.post("/OnBoarding/LoginUser", { data: encrypt(payload) })
    const data = await response?.data?.data

    if (data) {
      const userDetails = await getLoggedInUserDetails(data.token)
      const userData = { isLoggedIn: true, data: { ...data, ...userDetails } }
      return userData
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const sendResetOTP = async (email) => {
  const payload = { email: email }
  try {
    const response = await Axios.post("/OnBoarding/ResetPassword", payload)
    return response
  } catch (error) {
    throw new Error(error?.message)
  }
}

export const validateResetOTP = async (payload) => {
  try {
    const response = await Axios.post("/OnBoarding/ValidateResetPasswordOtp", {
      data: encrypt(payload),
    })
    return response
  } catch (error) {
    throw new Error(error?.message)
  }
}

export const resetPassword = async (payload) => {
  try {
    const response = await Axios.post("/OnBoarding/setPasswordOtp", { data: encrypt(payload) })
    return response
  } catch (error) {
    throw new Error(error?.message)
  }
}

export const validateBvn = async (payload) => {
  try {
    const response = await Axios.post("/Account/ValidateBvn", { data: encrypt(payload) })
    const decryptedData = JSON.parse(decrypt(response?.data))
    const data = await decryptedData?.data
    return data
  } catch (error) {
    throw new Error(error?.message)
  }
}

export const verifyBvn = async (payload) => {
  try {
    const response = await Axios.post("/Account/VerifyBvn", { data: encrypt(payload) })
    const data = await response?.data
    return data
  } catch (error) {
    throw new Error(error?.message)
  }
}

export const changePassword = async (payload) => {
  try {
    const response = await Axios.post("/OnBoarding/ChangePassword", {
      data: encrypt(payload),
    })
    return response
  } catch (error) {
    throw new Error(error?.message)
  }
}

export const logoutUser = async () => {
  try {
    const response = await Axios.post("/onboarding/logout")
    return response
  } catch (error) {
    throw new Error(error?.message)
  }
}
